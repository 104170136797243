<template>
    <v-autocomplete
      v-model="value"
      cache-items
      dense
      hide-selected
      :items="itemsList"
      :search-input.sync="searchItem"
      open-on-clear
      item-value="UsrId"
      item-text="UsrNom"
      outlined
      no-data-text="Introduzca texto a buscar"
      :label="label"
      return-object
      ref="UsrFindCtrl"
      :readonly="readonly"
      :clearable="!readonly"
      :menu-props="{'open-on-click': true}"
      @click="clickEvt"
      @click:append="clickAppendEvt"
      @input="inputEvt"
      @change="changeEvt"
      @blur="blurEvt"
    ></v-autocomplete>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null,
    }),
    props: ['value', 'perCatId', 'perCatIdAnyLevel', 'label', 'readonly', 'selectOnReadonly', 'loadOnMount'],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      value() {
        if (this.value != null && this.value != undefined && this.value != '') {
          this.loadItems('');
        }
      },
      perCatId() {
        this.$refs.UsrFindCtrl.cachedItems = [];
        this.loadItems('');
      },
      perCatIdAnyLevel() {
        this.$refs.UsrFindCtrl.cachedItems = [];
        this.loadItems('');
      }
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {   
          Action: 'GET_DATA_LIST_PAGINATED',
          CampoBusqueda: 'UsrNom',
          ValorBuscar: valFind,
          NumRegsPag: 35,
          NumPag: 1,
          EmpId: this.empId,
          PerCatId: this.perCatId,
          PerCatIdAnyLevel: this.perCatIdAnyLevel
        };

        var valorActual = this.value;
        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/usr", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
            this.itemsList = [];
            result.data.EntsList.forEach(element => {
              var itemObject = { 
                UsrId: element.UsrId, 
                UsrNom: element.UsrNom, 
                UsrLogin: element.UsrLogin, 
                UsrPwd: element.UsrPwd 
              };
              this.itemsList.push(itemObject);
            });

            if(valorActual != null) {
              // if (valorActual.length == null) {
              if (!Array.isArray(valorActual)) {  // es un objeto
                if (valorActual.UsrId != null) {
                  if (!this.itemsList.some(itm=>itm.UsrId === valorActual.UsrId)) {
                    this.itemsList.push(valorActual);
                  }
                }
              }
              else {
                valorActual.forEach(element => {  // es un array
                  if (!this.itemsList.some(itm=>itm.UsrId === element.UsrId)) {
                    this.itemsList.push(element);
                  }
                });
              }
            }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.UsrFindCtrl.isMenuActive) {
            this.$refs.UsrFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.UsrFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.UsrFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
          this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      },
    },
    mounted() {
      this.itemsList = [];
      if (this.loadOnMount) { this.loadItems(''); }
    }
  }
</script>